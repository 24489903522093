import React, { useState } from 'react'
import HeaderComponents from './HeaderComponents'
import ScrollAnimation from 'react-animate-on-scroll'
import axios from 'axios'
import { send } from 'react-ga';


export default function Contactanos(props) {

    let [datosEnviados, setDatosEnviados] = useState(false);
    const initialDatos = {
        "nombre": "",
        "email": "",
        "cooperativa": "",
        "consulta": "",
        "formName": "contacto"
    };

    const initialClases = {
        nombre: "",
        email: "",
        cooperativa: "",
        consulta: ""
    }

    const [datos, setDatos] = useState(initialDatos);
    const [clases, setClases] = useState(initialClases);
    const [mensajeClass, setMensajeClass] = useState('disabled')


    const handleInputChange = (event) => {
        console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const handleDatosClases = (string, key) => {
        let val = string;
        setClases((prevState) => {
            return { ...prevState, [key]: val };
        });
    };

    const sendDatos = () => {
        setDatosEnviados(true)
        setMensajeClass('disabled')
        console.log(datos)
        axios.post("https://b1t0wyo7gh.execute-api.us-east-1.amazonaws.com/prod/mailing-nodos", JSON.stringify({ datos }));
    }

    const requerirCampos = () => {
        let camposIncompletos = 0;
        let keys = ["nombre", "email", "cooperativa", "consulta"];
        keys.map((key) => {
            if (datos[key] === "") {
                handleDatosClases("incompleto", key);
                setMensajeClass('')
                camposIncompletos++;
            } else {
                handleDatosClases("disbaled", key);
            }
        });
        if (camposIncompletos == 0) {
            sendDatos()
        }
    };

    return (
        <div id='contactanos' className="contactanos-container">
            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <div className="container">
                    <HeaderComponents
                        title={"<h2>Contactanos</h2>"}
                        detail={'Inscribite o consultanos sobre las capacitaciones.'}
                        barra={'agenda-barra.png'}
                        textoVertical={"TE ESCUCHAMOS"}
                    />
                    <div className="contactanos-content">
                        {datosEnviados ? (
                            <div className="form" >
                                <div className="mensaje">
                                    <h3>¡Muchas gracias!</h3>
                                    <p>Responderemos lo antes posible</p>
                                </div>
                            </div>
                        ) : (
                                <div className="form">
                                    <div className="formulario">
                                        <label className={clases.nombre} htmlFor="nombre">
                                            NOMBRE.
                           <input onChange={handleInputChange} type="text" name="nombre" id="nombre" />
                                        </label>
                                        <label className={clases.cooperativa} htmlFor="cooperativa">
                                            COOPERATIVA.
                           <input onChange={handleInputChange} type="text" name="cooperativa" id="cooperativa" />
                                        </label>
                                        <label className={clases.email} htmlFor="email">
                                            EMAIL.
                           <input onChange={handleInputChange} type="email" name="email" id="email" />
                                        </label>
                                        <label className={clases.consulta} htmlFor="consulta">
                                            CONSULTA.
                           <textarea onChange={handleInputChange} type="text" name="consulta" id="consulta" />
                                        </label>
                                        <button onClick={requerirCampos} >ENVIAR</button>
                                    </div>
                                </div>
                            )}
                        <div className="logos">
                            <img className="logo-nodos" src="/img/logo-nodos-gris.png" alt="" />
                            <p className={mensajeClass} >Por favor, complete todos los campos del formulario</p>
                            <img className="logo-avion" src="/img/avion.png" alt="" />
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
        </div >
    )
}