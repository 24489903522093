import React, { useEffect } from 'react'
import ReactGa from 'react-ga'
import Header from '../Components/Header'
import Contactanos from '../Components/Contactanos'
import InternaTitles from '../Components/InternaTitles'
import Footer from '../Components/Footer'
import ParaQuienes from '../Components/ParaQuienes'
import Separador from '../Components/Separador'
import ScrollAnimation from 'react-animate-on-scroll'


export default function Gobierno(props) {

    const match = props.match.params.slug

    const rojo = '#D40030'

    useEffect(() => {
        ReactGa.pageview(`/gobierno-cooperativo/${match}`)
    })



    return (
        <div className="gobierno-container">
            <Header
                fondo={'fondo-rojo'}
                rayas={'rayas-azules.png'}
                subtitle={'Gobierno Cooperativo'}
                onlyFirst={true}
                ejeHeader={true}
            />


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >

                {match == 'programa-vilca' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-roja.png'}
                                        title={'Programa VILCA I'}
                                        subtitle={'Dirigido a consejeros de cooperativas'}
                                        titleColor={'texto-rojo'}
                                        textoVertical={'EJES'}
                                        style={{lineHeight:'2rem'}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div style={{ top: '-8rem' }} className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>
                                    <br />
                                    Este programa tiene el propósito de contribuir al desarrollo y fortalecimiento de los Consejos.<br />
                                    <br />
                                    Busca que  sus consejeros participen en espacios de aprendizaje continuos con ejes en:<br />
                                    <br />
                                    Identificar las nuevas oportunidades de desarrollo que requiere el ejercicio del rol de consejero de cara al futuro.
                                    Apropiarse de conocimientos y herramientas para el desarrollo de nuevas habilidades.
                                    Comprender la importancia de definir una estrategia de comunicación en función de los objetivos, mensajes e interlocutores.
                                </p>

                                <h3 className="title">QUÉ</h3>
                                <p>
                                    <br />
                                    <h4 className="texto-rojo">Módulo 1:</h4>
                                    Nuevos avances tecnológicos y los cambios demográficos y sus efectos en la economía de los países y de las empresas.
                                    Posibles efectos que tendrán estas tendencias en los negocios actuales y futuros de las cooperativas. Se considerarán tanto los efectos directos como los efectos indirectos, es decir, los efectos sobre el negocio en sí, y los efectos sobre la capacidad de la empresa para lograr ser competitiva.
                                    Trabajo guiado en los equipos. Alternativas de acción que pueden surgir de las dos secciones anteriores y de las discusiones en el equipo. Idealmente cada equipo debería delinear una o dos propuestas de planes de acción para poder desarrollar posteriormente. <br />
                                    <br />
                                    <h4 className="texto-rojo">Módulo 2:</h4>
                                    Introducir el Modelo de Gestión Cooperativa, indagar en buenas prácticas de gobierno cooperativo y en el rol del consejero.
                                    Modelo de Gestión: Modelo de Gestión Cooperativa (MGC), Objetivos y Beneficios, Dimensiones Clave del Modelo, Liderazgo Cooperativo, Gestión, Cooperativa y Resultados de 4 impactos, Aplicaciones del Modelo de Gestión.
                                    Gobierno Cooperativo: Requisitos del Modelo de Gestión, Buenas Prácticas de Gobierno Cooperativo, Autoevaluación del sistema de Gobierno de nuestra cooperativas. Rol del Consejero: Rol del Consejero, Vinculación de la Gerencia, Autoevaluación y posibles mejoras <br />
                                    <br />
                                    <h4 className="texto-rojo">Módulo 3:</h4>
                                    El taller de Aplicación se focalizará en cómo el consejo puede contribuir a llevar a su cooperativa a un enfoque de “productor / cliente”, buscando influir en la forma en que su Cooperativa mejora su capacidad para poner en el centro al  productor y al servicio brindado.
                                    Se compartirán metodologías de empresas de clase mundial que inspiren a pensar juntos formas  específicas de cómo mejorar el enfoque en el productor (Customer centricity y Data analytics del servicio al productor), alineado con el modelo de Gestión, y con el Pensar el Futuro de módulos anteriores. <br />
                                    <br />
                                    <h4 className="texto-rojo">Módulo 4:</h4>
                                    Valorar la importancia de definir la estrategia de comunicación en función del objetivo y  el  interlocutor.
                                    Reflexionar sobre cómo planificar las comunicaciones para ser efectivos.
                                    Analizar los principales medios de comunicación disponibles para los consejeros.
                                </p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>4 módulos de 2 horas cada uno</p>
                                <div className="container" style={{height:`${window.screen.width < 768 ? "100%": '15rem'}`,paddingRight:`${window.screen.width < 768 ? '2rem' :'30rem'}`,marginLeft:'-1rem'}}>
                                    <h3 style={{fontSize:'1rem'}}><strong>FECHA</strong> - 8:30 a 10:30 hs</h3>
                                    <div className="row">
                                        {/* <div className="col">
                                            <h4>Grupo 1</h4>
                                            <p>M1: 8 de abril </p>
                                            <p>M2: 15 de abril </p>
                                            <p>M3: 23 de abril </p>
                                            <p>M4: 29 de abril</p>
                                        </div> */}
                                        <div className="col">
                                            <h4>1era Edición</h4>
                                            <p>M1: 12 de agosto</p>
                                            <p>M2: 24 de agosto</p>
                                            <p>M3: 1 de septiembre</p>
                                            <p>M4: 10 de septiembre</p>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col">
                                            <h4>Grupo 2</h4>
                                            <p>M1: 18 mayo</p>
                                            <p>M2: 31 mayo</p>
                                            <p>M3: 8 junio</p>
                                            <p>M4: 17 junio</p>
                                        </div>
                                    </div> */}
                                </div>
                            <ParaQuienes
                                onlyButton={true}
                                colorButton={'fondo-blanco'}
                                buttonBorderColor={rojo}
                                colorLink={rojo}
                                eventName={'Programa VILCA a distancia'}
                                link={'https://forms.gle/TYnhoQdXbbjQdoaW8'}
                                style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                idName={"gobierno"}
                            />
                            </div>
                        </div>
                    </div>
                )}
                {match == 'programa-vilca-ii' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-roja.png'}
                                        title={'Programa VILCA II'}
                                        subtitle={'Dirigido a consejeros de cooperativas'}
                                        titleColor={'texto-rojo'}
                                        textoVertical={'EJES'}
                                        style={{lineHeight:'2rem'}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div style={{ top: '-8rem' }} className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>
                                    Zoom en comunicación. poder identificar cuándo un problema o situación es de comunicación y 
                                    comprender la lógica. Mapear e incorporar competencias de comunicación claves para el liderazgo. 
                                </p>

                                <h3 className="title">QUÉ</h3>
                                <p>
                                    <br />
                                    <h4 className="texto-rojo">Módulo 1 Oratoria:</h4>
                                        Preparación personal e internalización del mensaje. La voz y la comunicación no verbal. Pensar en el público. Generación de contexto. Teatralización, storytelling y feedback.
                                    <br />
                                    <h4 className="texto-rojo">Módulo 2 Vocería:</h4>
                                        Objetivo del mensaje ¿dónde pongo el esfuerzo? Elaboración del mensaje núcleo. Sentido, consistencia y alineación de los micromensajes al objetivo y a la estrategia de la cooperativa.
                                    <br />
                                    <h4 className="texto-rojo">Módulo 3 Negociación:</h4>
                                        El método Harvard de la negociación colaborativa. Tácticas, estrategias y detección de escenarios. La negociación en la proyección de relaciones a largo plazo.                                     <br />
                                    <h4 className="texto-rojo">Módulo 4 Conversaciones cruciales:</h4>
                                        Conflicto y comunicación no violenta. Conversaciones complejas y programadas. La gestión del rumor. Conversaciones en contextos de incertidumbre.                                 </p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>4 módulos de 2 horas cada uno</p>
                                <div className="container" style={{height:`${window.screen.width < 768 ? "100%": '15rem'}`,paddingRight:`${window.screen.width < 768 ? '2rem' :''}`,marginLeft:'-1rem'}}>
                                    <h3 style={{fontSize:'1rem'}}><strong>FECHA</strong> - 8:30 a 10:30 hs - Seleccione la edición en la que quiera anotarse</h3>
                                    <h3>1era Edición: inicia 29 de junio </h3>
                                    <div className="buttonCustom" style={{marginBottom:'2rem'}}>
                                        <a target="_blank" href="https://forms.gle/aRMXARxFPGkqpqSr5">
                                            <button className="texto-rojo borde-rojo boton-rojo">¡Inscribirme!</button>
                                        </a>
                                    </div>
                                    <h3>2da Edición: inicia 28 de septiembre </h3>
                                    <div className="buttonCustom">
                                        <a target="_blank" href="https://forms.gle/t8iT12ou7pTMcHK6A">
                                            <button className="texto-rojo borde-rojo boton-rojo">¡Inscribirme!</button>
                                        </a>
                                    </div>
                                    
                                </div>
                            {/* <ParaQuienes
                                onlyButton={true}
                                colorButton={'fondo-blanco'}
                                buttonBorderColor={rojo}
                                colorLink={rojo}
                                eventName={'Programa VILCA II a distancia'}
                                link={'https://forms.gle/aRMXARxFPGkqpqSr5'}
                                style={{display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:'-4rem',marginBottom:'-10rem'}}
                                idName={"gobierno"}
                            /> */}
                            </div>
                        </div>
                    </div>
                )}
                {match == 'modo-remoto' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-roja.png'}
                                        subtitle={'Comunicación en modo remoto. Dinámicas y herramientas.'}
                                        titleColor={'texto-rojo'}
                                        subtitleColor={'texto-rojo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Que los consejos puedan manifestar sus dudas sobre la comunicación virtual y comenzar a poner en juego algunas habilidades que les brindarán mayor confianza.</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>
                                    Vidriera de herramientas de comunicación virtual. Presentación de las herramientas de comunicación virtual más utilizadas. ¿Cuándo utilizar cada herramienta? Entrenar el uso de las diversas herramientas en función de los objetivos de la comunicación. Herramientas sincrónicas y asincrónicas. Facilitación y participación en reuniones virtuales.
                                    Parte de la tarea del consejero es trabajar de manera colectiva discutiendo y acordando temas de la cooperativa. Cómo planificar, participar y moderar las reuniones virtuales para que sean efectivas y se alcancen los objetivos del encuentro.
                                    EMPATÍA y conversar sobre las EMOCIONES
                                    Cómo mantenernos perceptivos con nuestro interlocutores a través de los medios virtuales.
                                    ¿Qué hacer y cómo favorecer que los otros se compartan sus emociones?

                    </p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Consejeros'}
                            duracionContent={'Duración 1 hora y media'}
                            cuandoContent={''}
                            borderColor={'borde-rojo'}
                            colorButton={'fondo-rojo'}
                            colorLink={'texto-rojo'}
                            eventName={'Comunicación en modo remoto. Dinámicas y herramientas.'}
                        />
                    </div>
                )}
                {match == 'simulador-abierto' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-roja.png'}
                                        title={'Simulador Abierto'}
                                        subtitle={'Dirigido a consejeros de cooperativas y funcionarios'}
                                        titleColor={'texto-rojo'}
                                        textoVertical={'EJES'}
                                        style={{lineHeight:'2rem'}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">En cuanto a la experiencia de simulación</h3>
                                <p>Trabaja la forma de decisiones y su impacto en el negocio y se plantean situaciones que se deberán resolver, permitiendo identificar el proceso de toma de 
                                    decisiones y su impacto en la sostenibilidad de la cooperativa en el futuro. Esta experiencia permite abrir conversaciones sobre diferentes acciones a tomar.
                                    Se analiza el impacto de las decisiones y se rescatan lecciones aprendidas. En función de la realidad de la cooperativa se adjustan previamente los casos a trabajar.
                                    La duración estimada es de tres horas, pudiendo bajar a dos horas y media. Puede hacerlo el consejo solo, consejo + funcionarios, y pueden convocar a los jóvenes también.
                                </p>
                                <h3 className="title">FECHA</h3>
                                <p>Inicia el 10 de mayo</p>    
                                <ParaQuienes
                                    onlyButton={true}
                                    colorButton={'fondo-blanco'}
                                    buttonBorderColor={rojo}
                                    colorLink={rojo}
                                    eventName={'Simulador Abierto'}
                                    link={'https://forms.gle/jtbhYt4wKxokgT8D9'}
                                    style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                    idName={"gobierno"}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </ScrollAnimation>


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >

                <Separador
                    image={'../img/fondo-separador-2.svg'}
                    parallax={'parallax-img'}
                />
            </ScrollAnimation>

            <Contactanos />
            <Footer />
        </div>
    )
}