import React from 'react'
import IconosRedes from './IconosRedes'

export default function NavbarHeader(props) {
    return (
        <div className={`navbar-header-container ${props.buttonClass}`}>
            <div className="container">
                <div className="mainmenu">
                    <h1>INDICE</h1>
                    <ul className="menu-principal">
                        <li>
                            <a
                                href="/#agenda"
                                aria-current="page"
                            >AGENDA</a>
                        </li>
                        <li>
                            <a
                                href="/#colaboratorios"
                                aria-current="page"
                            >COLABORATORIO</a>
                        </li>
                        <li>
                            <a
                                href="/#caja-de-herramientas"
                                aria-current="page"
                            >CAJA DE HERRAMIENTAS</a>
                        </li>
                        <li>
                            <a
                                href="/#acerca-de-nodos"
                                aria-current="page"
                            >ACERCA DE NODOS</a>
                        </li>
                        <li>
                            <a
                                href="/#contactanos"
                                aria-current="page"
                            >CONTACTANOS</a>
                        </li>
                    </ul>
                    <div className="iconos-redes">
                        <IconosRedes />
                    </div>
                </div>
            </div>
        </div>
    )
}