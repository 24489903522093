import React, { useEffect } from 'react'
import ReactGa from 'react-ga'
import Header from '../Components/Header'
import Contactanos from '../Components/Contactanos'
import InternaTitles from '../Components/InternaTitles'
import Footer from '../Components/Footer'
import ParaQuienes from '../Components/ParaQuienes'
import Separador from '../Components/Separador'
import ScrollAnimation from 'react-animate-on-scroll'




export default function Gestion(props) {

    const match = props.match.params.slug

    const verde = '#43A363'

    useEffect(() => {
        ReactGa.pageview(`/gobierno-cooperativo/${match}`)
    })

    return (
        <div className="gestion-container">
            <Header
                fondo={'fondo-verde'}
                rayas={'rayas-azules.png'}
                subtitle={'CULTURA COOPERATIVA'}
                onlyFirst={true}
                ejeHeader={true}
            />


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                {match == 'trabajo-equipo' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-verde.png'}
                                        title={'Taller Web Trabajo en equipo. Poniendo en juego los valores cooperativos.'}
                                        titleColor={'texto-verde'}
                                        style={{lineHeight:'2rem',marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                        textoVertical={'EJES'}
                                        subtitle={'Dirigido a todos los integrantes de las cooperativas'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Experimentar cómo los valores cooperativos facilitan y favorecen la potencia de un equipo cuando los ponemos en juego. </p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>Es una  vivencia desde lo personal y lo grupal. Una propuesta para revitalizar la vigencia de los valores cooperativos hoy. Somos protagonistas en la construcción de la cultura cooperativa. </p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>1 módulo de 2 horas.</p>
                                <h3 className="title">FECHA</h3>
                                <p>26 de abril - 8:30 a 10:30hs</p>
                                <ParaQuienes
                                    onlyButton={true}
                                    colorButton={'fondo-blanco'}
                                    buttonBorderColor={verde}
                                    colorLink={verde}
                                    eventName={'Trabajo en equipo. Apoyando los valores cooperativos.'}
                                    style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                    idName={"gestion"}
                                    link={'https://forms.gle/DxUPy8wLiVEX2ros7'}
                                />
                            </div>
                        </div>
                    </div>

                )}

                {match == 'emociones' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-verde.png'}
                                        subtitle={'Cómo gestionar las emociones'}
                                        titleColor={'texto-verde'}
                                        subtitleColor={'texto-verde'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Que los participantes puedan encontrar respuestas a  estas preguntas y apropiarse de herramientas para transferirlas al cotidiano: ¿Hay algo que podamos hacer al respecto?
                                ¿Qué herramientas podemos incorporar para sentirnos mejor? ¿Cómo nos vinculamos con las otras personas? ¿La conexión digital es un impedimento para generar empatía?
                                ¿Podemos escondernos detrás de la pantalla? ¿Es posible gestionar las emociones de otros?
</p>
                                <h3 className="title">CONTENIDOS</h3>


                                <p>AUTOCONOCIMIENTO. Emociones que aparecen en el contexto actual. Cómo funciona nuestro cerebro y cómo se vinculan los pensamientos y las emociones. EMPATIA. Qué es la empatía. Cómo conectar y acercar la distancia en el contexto actual. GESTIÓN DE LAS EMOCIONES PROPIAS Y AJENAS. Herramientas de la inteligencia emocional que ayudan a la autorregulación. La diferencia entre control y dominio emocional. Cómo gestionar las emociones de otros.
                                EL MANEJO DE LA INCERTIDUMBRE. Qué es la incertidumbre y qué nos provoca? ¿Por qué? Cómo ayudar al cerebro a no despertar la sensación de amenaza.
</p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'A todos los integrantes de la cooperativa. '}
                            duracionContent={'1 hora y media.'}
                            cuandoContent={''}
                            borderColor={'borde-verde'}
                            colorButton={'fondo-verde'}
                            link={''}
                            colorLink={'texto-verde'}
                            eventName={'Cómo gestionar las emociones'}
                        />
                    </div>

                )}
            </ScrollAnimation>

            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >

                <Separador
                    image={'../img/fondo-separador-2.svg'}
                    parallax={'parallax-img'}
                />
            </ScrollAnimation>

            <Contactanos />
            <Footer />
        </div>
    )
}