import React from 'react'
import HeaderComponents from './HeaderComponents'

export default function AcercaDe(props) {
    return (
        <div id='acerca-de-nodos' className="acerca-de-container">
            <div className="container">
                <HeaderComponents
                    title={"<h2> Acerca de Nodos</h2>"}
                    barra={'barra-gris.png'}
                    textoVertical={"SOMOS"}
                />
                <div className="acerca-de-content">
                    <div className="text">
                        <p>Fundación Nodos fue creada por la Asociación de Cooperativas Argentinas (ACA), La Segunda Seguros, Avalian y Coovaeco para contribuir al fortalecimiento y al desarrollo del ecosistema cooperativo.</p>
                        <p>Contribuimos al fortalecimiento del ecosistema cooperativo a través del diseño y desarrollo de estrategias que promuevan cambios y aprendizajes.
</p>
                        <p>
                            Promovemos la integración entre las personas y las organizaciones del Grupo Cooperativo y la construcción de su identidad en cada propuesta.

</p>
                        <p className="no-bottom" >
                            ¿A quiénes nos dirigimos?
</p>
                        <p className="no-top" >
                            A las organizaciones cooperativas, sus integrantes y a las comunidades.

</p>

                        <h4 className="no-bottom" ><strong>Nuestro equipo</strong>
</h4>
                        <p className="no-top" >
                            Somos un equipo interdisciplinario que trabaja  con una red de facilitadores y en vinculación con universidades para potenciar nuestra propuesta.
</p>


                        <img src="/img/logo-nodos-gris.png" alt="" />
                    </div>
                    <div className="imagen">
                        <img src="/img/acerca-de-imagen.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}