import React from 'react'
import Parallax from 'react-rellax'

export default function Separador(props) {
    return (
        <Parallax className="parallax" porcentage={0} speed={-.1} >
            {
                props.simple ? 
                <div className="fondo-separador separador-container" style={{backgroundColor:'#01467E',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'2rem'}}>
                    <div className="texto-sep" style={{width:'60%',display:'flex',flexDirection:'column',alignItems:'flex-start',color:'white'}}>
                        {
                            window.screen.width < 768 ?
                                <>
                                    <h4><strong>Descubrí distintas maneras de potenciar a tu cooperativa.</strong></h4>
                                </>
                                :
                                <>
                                    <h2><strong>Descubrí distintas maneras de potenciar</strong></h2>
                                    <h2><strong>a tu cooperativa.</strong></h2>
                                </>
                        }
                    </div>
                </div>
                :
                <div className="fondo-separador fondo-azul">
                    <Parallax className={props.parallax} porcentage={0} speed={-.2} >
                        <div style={{ backgroundImage: `url(${props.image})` }} className="separador-container">

                            {props.texto && (
                                <Parallax className="parallax-text" porcentage={0} speed={-.3} >
                                    <div className="separador-texto">

                                        <h4>Descubrí distintas maneras de potenciar</h4>
                                        <h4>a tu cooperativa.</h4>

                                    </div>
                                </Parallax>

                            )}

                        </div>
                    </Parallax>
                </div>
            }
        </Parallax>


    )
}