import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'


export default function Slider(props) {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className={`slider-container ${props.onlyFirst ? 'centerAlign' : ""}`} >
            <div className="container">
                {
                    props.onlyFirst ?
                        <div className="slider-1">
                            <div className="fondo-title"></div>
                            <h1 className="slider-title" >Potenciá tu cooperativa</h1>
                            <p className="slider-detail" >Conocé nuestra propuesta de capacitación. Queremos estar más cerca y acompañarte.</p>
                        </div>
                    
                    :
                
                    <Carousel fade={true} interval={3000} touch={true} controls={false} indicators={true} keyboard={true} activeIndex={index} onSelect={handleSelect}>
                        <Carousel.Item>
                            <div className="slider-1">
                                <div className="fondo-title"></div>
                                <h1 className="slider-title" >Potenciá tu cooperativa</h1>
                                <p className="slider-detail" >Conocé nuestra propuesta de capacitación dirigida a las cooperativas. Queremos estar más cerca y acompañarte.</p>

                                <div className="logos-container">
                                    <div className='container-item' >
                                        <div className="item">
                                            <p className="numero">01</p>

                                        </div>
                                        <p className="item-title" >Liderazgo</p>
                                    </div>
                                    <div className='container-item' >
                                        <div className="item">
                                            <p className="numero">02</p>

                                        </div>
                                        <p className="item-title" >Estrategia y <br></br>Gestión</p>
                                    </div>
                                    <div className='container-item' >
                                        <div className="item">
                                            <p className="numero">03</p>

                                        </div>
                                        <p className="item-title" >Gobierno <br></br>Cooperativo</p>
                                    </div>
                                    <div className='container-item' >
                                        <div className="item">
                                            <p className="numero">04</p>

                                        </div>
                                        <p className="item-title" >Cultura <br></br>Cooperativa</p>
                                    </div>
                                </div>
                                <h6 className="texto-vertical texto-rosa esquina" >#Estar+Cerca</h6>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="slider-2">
                                <div className="fondo-title"></div>
                                <h1 className="slider-title" >Conversaciones de mañana</h1>
                                <p className="slider-detail" >Todos los jueves, 30 minutos de reflexión e inspiración para empezar el día.</p>
                                <div className="logos-container">
                                    <img src="/img/tomando-cafe.svg" alt="" />
                                    <h6 className="texto-vertical texto-rosa " >#Estar+Cerca</h6>
                                </div>
                            </div>
                        </Carousel.Item>

                        <Carousel.Item>
                            <div className="slider-3">
                                <img src="/img/slider3.png" alt="" />
                                <div className="text">
                                    <div className="fondo-title"></div>
                                    <h1 className="slider-title" >Caja de herramientas</h1>
                                    <p className="slider-detail" > Encontrá tips para
                                repensar las prácticas diarias de tu cooperativa.</p>
                                </div>
                                <h6 className="texto-vertical texto-rosa esquina" >#Estar+Cerca</h6>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="slider-4">
                                <div className="text">
                                    <div className="fondo-title"></div>
                                    <h1 className="slider-title" >Somos comunidad</h1>
                                    <p className="slider-detail" >Mirá cómo otras cooperativas están afrontando los nuevos desafíos.  </p>
                                </div>
                                <img src="/img/slider4.png" alt="" />
                                <h6 className="texto-vertical texto-rosa esquina" >#Estar+Cerca</h6>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                }
            </div>
        </div>
    )
} 