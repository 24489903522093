import React from 'react'

export default function IconosRedes(props) {
    return (
        <div className="iconos-redes-container">
            <a href="https://www.facebook.com/fundacionnodos" target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24.349" height="46.632" viewBox="0 0 24.349 46.632">
                    <g transform="translate(-898.282 -607)" >
                        <path
                            d="M2979.9,387.158a1.2,1.2,0,0,0-.521-.8,1.861,1.861,0,0,0-.974-.188c-1.257-.006-2.53,0-3.785.027a25.3,25.3,0,0,0-5.435.529,8.554,8.554,0,0,0-6.6,6.149,32.279,32.279,0,0,0-1.138,9.833l-4.081-.015a1.42,1.42,0,0,0-1.734,1.737l.013,5.74a1.461,1.461,0,0,0,.411,1.245,1.438,1.438,0,0,0,.884.192l4.261-.006h.359c0,2.344.005,6.17.007,9.975,0,3.733.005,7.446.008,9.718a1.143,1.143,0,0,0,1.268,1.507h7.116a1.163,1.163,0,0,0,1.3-1.516c.024-2.275.048-6.1.07-9.8.022-3.636.045-7.395.068-9.661l6.236.016a1,1,0,0,0,1.216-1.228l-.039-6.334c-.009-1.325-1.062-1.332-2.08-1.34h-.041l-3.293-.019-1.9-.011c-.082-1.859-.067-3.636.907-4.973,1.526-2.094,4.62-2.015,5.879-1.884a1.638,1.638,0,0,0,1.221-.186,1.555,1.555,0,0,0,.392-1.233l.084-6.545A3.9,3.9,0,0,0,2979.9,387.158Zm-10.573,16a1.486,1.486,0,0,0,1.722,1.9l4.563-.062a1.18,1.18,0,0,1,.948.265,1.136,1.136,0,0,1,.181.7c.039,1.006.03,2.026-.027,3.031a.708.708,0,0,1-.188.541.7.7,0,0,1-.431.089l-4.748-.012a1.6,1.6,0,0,0-2.083,1.819c-.018,2.254-.037,6.074-.055,9.767-.017,3.58-.035,7.281-.052,9.5l-5.457.005c0-2.569-.009-6.612-.013-10.641,0-3.984-.009-7.954-.013-10.471v-.065l-5.9-.032c-.038-1.544-.041-3.117-.01-4.677l4.088-.02a2.012,2.012,0,0,0,1.657-.529,1.927,1.927,0,0,0,.294-1.181c.017-.828-.007-1.671-.029-2.485a34.567,34.567,0,0,1,.034-3.775c.382-4.627,3.256-7.807,7.688-8.507a27.065,27.065,0,0,1,6.275-.01,48.42,48.42,0,0,1-.034,5.53c-2.49.129-4.854.383-6.467,1.949C2969.392,397.605,2969.281,400.547,2969.323,403.162Z"
                            transform="translate(-2057.342 220.831)"
                            fill="#fff"
                        >
                        </path>
                    </g>
                </svg>
            </a>
            <a href="https://www.linkedin.com/company/fundacion-nodos" target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="41.932" height="43.985" viewBox="0 0 41.932 43.985">
                    <g transform="translate(-959.165 -609.227)" >
                        <g transform="translate(959.165 609.227)" >
                            <path
                                d="M3263.027,535.085l.025.025a1.048,1.048,0,0,0,.871.409h7.741a1.042,1.042,0,0,0,.97-.563l.019-.036.036-.019a1.042,1.042,0,0,0,.564-.972V506.982a1.059,1.059,0,0,0-.307-.78c-.013-.015-.019-.02-.024-.026l-.018-.019-.025-.024a1.061,1.061,0,0,0-.786-.311h-8.385a1.056,1.056,0,0,0-.845.37,1.273,1.273,0,0,0-.245,1.039l0,.024v26.96A1.047,1.047,0,0,0,3263.027,535.085Zm1.913-26.943h5.994V533.2h-5.994Z"
                                transform="translate(-3261.421 -491.533)"
                                fill="#fff"
                            >
                            </path>
                            <path
                                d="M3379.343,535.085l.025.025a1.048,1.048,0,0,0,.871.409h7.74a1.042,1.042,0,0,0,.971-.563l.019-.036.036-.019a1.042,1.042,0,0,0,.564-.972c0-3.317-.022-6.688-.043-9.948l-.017-2.7q-.014-2.263-.021-4.525c-.006-2.252-.015-5.055-1.4-7.189-2.263-3.487-6.693-3.684-10.3-3.467l-.014,0a.342.342,0,0,1-.048.007,10.9,10.9,0,0,0-5.153,1.929l-.205.143v-1.2a1.047,1.047,0,0,0-.755-1.051l-.015-.006a1.187,1.187,0,0,0-.5-.1h-7.955a1.066,1.066,0,0,0-.755.283l-.016.015-.02.009a1.034,1.034,0,0,0-.62,1v27.09a1.046,1.046,0,0,0,.407.869l.024.025a1.049,1.049,0,0,0,.872.409h7.74a1.043,1.043,0,0,0,.971-.563l.02-.036.036-.019a1.042,1.042,0,0,0,.564-.972c0-.128.056-12.7.051-12.874-.07-2.326-.188-6.219,3.642-6.364.1,0,.193-.006.292-.006,1.1,0,2.289.276,2.582,1.875l0,.024v17.633A1.046,1.046,0,0,0,3379.343,535.085Zm-1.76-22.631a6.558,6.558,0,0,0-6.482,2.6,8.646,8.646,0,0,0-1.109,4.911c.039,3.986.058,8.272.058,13.1v.13h-5.995V508.141h5.995v1.905a5.145,5.145,0,0,1,.095.641.328.328,0,0,0,.17.283c.7.376,3.091-.9,4.111-1.441.275-.146.457-.243.545-.279a13.257,13.257,0,0,1,6.86-.828,6.327,6.327,0,0,1,3.521,1.477c1.578,1.478,1.782,3.82,1.849,5.9.13,4.041.1,8.155.073,12.133-.012,1.684-.024,3.425-.024,5.135v.13h-5.988l-.005-.125c-.1-2.219-.027-4.474.043-6.654a75.185,75.185,0,0,0-.012-7.615l-.006-.086C3381.087,515.905,3380.885,513,3377.583,512.454Z"
                                transform="translate(-3347.637 -491.533)"
                                fill="#fff"
                            >
                            </path>
                            <path
                                d="M3260.536,396.245a6.8,6.8,0,0,0-6.393,4.12,5.246,5.246,0,0,0,3.8,7.611c3.224.805,7.065.245,8.178-3.741a6.654,6.654,0,0,0-.643-5.077,6.055,6.055,0,0,0-3.733-2.886,1.13,1.13,0,0,0-.574-.006l-.021.005-.021,0C3260.931,396.252,3260.732,396.245,3260.536,396.245Zm.832,2.369.044,0,.026.006a4.064,4.064,0,0,1,2.447,5h0a3.122,3.122,0,0,1-3.328,2.4,8.3,8.3,0,0,1-1.991-.275c-1.973-.492-2.92-1.82-2.532-3.551A4.814,4.814,0,0,1,3261.368,398.613Z"
                                transform="translate(-3253.598 -396.23)"
                                fill="#fff"
                            >
                            </path>
                        </g>

                    </g>
                </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCok2neEAeAvAkQ6pFb3O4DA" target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="46.5" height="33.571" viewBox="0 0 46.5 33.571">
                    <g transform="translate(-1033 -620.365)" >
                        <g transform="translate(1033 620.365)" >
                            <path
                                d="M3759.965,537.374c-.263-.073-.631-.192-1.057-.33a22.96,22.96,0,0,0-6.063-1.352,2.97,2.97,0,0,0-1.69.4,1.392,1.392,0,0,0-.579,1.172l-.006.194a114.139,114.139,0,0,0,.055,13.265,2.63,2.63,0,0,0,1.014,2.11c1.141.758,3.066.26,4.416-.181,3.554-1.162,7.142-2.693,10.308-4.044l.346-.147c1.427-.6,3.2-1.35,3.949-2.678.648-1.153.353-2.36-.879-3.586C3767.471,539.9,3762.351,538.036,3759.965,537.374Zm8.064,8.232c-.288.6-2.091,1.228-4.073,1.869-.474.153-.882.285-1.094.372-1.848.757-3.537,1.42-5.164,2.026-.253.095-.561.233-.886.379a7.756,7.756,0,0,1-2.768.874,1.281,1.281,0,0,1-1.085-.48l-.015-.02-.006-.025a12.444,12.444,0,0,1-.263-2.314c-.007-.135-.013-.247-.018-.324v-.007l.009-.22c.05-1.216.1-2.473.1-3.724,0-.5-.016-1.012-.029-1.506-.024-.872-.048-1.773-.007-2.661.042-.92.171-1.363.46-1.582.342-.26.857-.143,1.639.035.13.03.267.061.414.093a38.7,38.7,0,0,1,8.161,2.725c.165.077.383.169.636.275,1.328.558,3.551,1.493,4.068,2.852A1.678,1.678,0,0,1,3768.029,545.606Z"
                                transform="translate(-3737.236 -527.893)"
                                fill="#fff"
                            >
                            </path>
                            <path
                                d="M3695.827,491.826a54.143,54.143,0,0,0-.377-10.774h0a4.494,4.494,0,0,0-1.071-2.391l-.015-.018c-1.7-2.653-6.6-2.581-9.84-2.533l-.3,0c-2.917.04-5.924-.038-8.831-.113a140.534,140.534,0,0,0-14.267.113l-.313,0c-3.241-.048-8.14-.12-9.833,2.533l-.014.018a4.564,4.564,0,0,0-1.08,2.392,53.648,53.648,0,0,0-.38,10.62c.014.478.029.955.042,1.432.036,1.215.017,2.464,0,3.671a40.939,40.939,0,0,0,.339,7.416,4.558,4.558,0,0,0,1.08,2.39l.014.018c1.682,2.635,6.546,2.574,9.765,2.533l.371,0a184.951,184.951,0,0,0,18.524.172h.035c.925-.022,1.917,0,2.965.031,4.213.109,8.989.233,11.742-2.749a4.492,4.492,0,0,0,1.071-2.39,40.937,40.937,0,0,0,.339-7.417c-.018-1.207-.036-2.454,0-3.67Q3695.806,492.465,3695.827,491.826Zm-3.28,12.674a4.169,4.169,0,0,1-1.871,1.555,12.886,12.886,0,0,1-4.643.758l-.549.023c-4.785.224-9.506.417-14.14.417-3.831,0-7.6-.131-11.3-.485-.41-.039-.882-.061-1.383-.085a12.374,12.374,0,0,1-4.013-.606c-2.852-1.263-2.816-4.5-2.788-7.105,0-.186,0-.369,0-.549l.046-11.568c0-.161,0-.363-.007-.6-.032-1.776-.092-5.082,1.276-6.475a2.053,2.053,0,0,1,1.492-.63,14.308,14.308,0,0,1,5.293-.732c.492-.008.957-.015,1.4-.035,2.871-.13,5.778-.211,8.644-.242,3.57-.039,7.207,0,10.807.116,2.1.067,3.857.143,5.519.238l.506.027c2.371.122,5.32.273,6.146,2.778a13.773,13.773,0,0,1,.44,4.528c0,.338-.01.687-.008,1.026v.185c.015,3.731.03,7.588.054,11.382,0,.236.005.478.009.723C3693.515,500.927,3693.549,502.946,3692.546,504.5Z"
                                transform="translate(-3649.417 -475.876)"
                                fill="#fff"
                            >
                            </path>
                        </g>

                    </g>
                </svg>
            </a>
        </div>
    )
}