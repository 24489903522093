import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import ReactGa from 'react-ga'
import Home from './Pages/Home'
import Liderazgo from './Pages/Liderazgo';
import Estrategia from './Pages/Estrategia';
import Gobierno from './Pages/Gobierno';
import Gestion from './Pages/Gestion';
import ScrollTop from './Components/ScrollTop';


export default function Main(props) {

  useEffect(() => {
    ReactGa.initialize('UA-170554987-1')
    ReactGa.pageview('/')
  })

  return (
    <div>
      <Router>
        <ScrollTop>
          <Switch>
            <Route
              exact
              path='/'
              component={Home}
            />
            <Route
              exact
              path="/liderazgo/:slug"
              render={(props) => <Liderazgo  {...props} />}
            />
            <Route
              exact
              path='/estrategia/:slug'
              render={(props) => <Estrategia  {...props} />}
            />
            <Route
              exact
              path='/gobierno-cooperativo/:slug'
              render={(props) => <Gobierno  {...props} />}
            />
            <Route
              exact
              path='/gestion/:slug'
              render={(props) => <Gestion  {...props} />}
            />
          </Switch>
        </ScrollTop>
      </Router>
    </div>
  )

}


