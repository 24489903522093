import React from 'react'
import Header from '../Components/Header'
import Agenda from '../Components/Agenda'
import Separador from '../Components/Separador'
import Inspiracion from '../Components/Inspiracion'
import Reflexionar from '../Components/Reflexionar'
import Material from '../Components/Material'
import AcercaDe from '../Components/AcercaDe'
import Contactanos from '../Components/Contactanos'
import Footer from '../Components/Footer'
import ScrollAnimation from 'react-animate-on-scroll'

export default function Home(props) {
    return (
        <div className="home-container">
            <Header
                fondo={'fondo-azul'}
                rayas={'rayas-amarillas.png'}
                slider={true}
                onlyFirst={true}
            />
            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Agenda />
            </ScrollAnimation>
            {/* <Separador /> */}

            {/* <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Separador
                    simple={true}
                />
            </ScrollAnimation> */}


            {/* <Inspiracion /> */}


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Reflexionar />
            </ScrollAnimation>

            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Material />
            </ScrollAnimation>

            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <AcercaDe />
            </ScrollAnimation>

            {/* <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Separador
                    image={'../img/fondo-separador-2.svg'}
                    parallax={'parallax-img-2'}
                />
            </ScrollAnimation> */}


            <Contactanos />


            <Footer />
        </div>
    )
}