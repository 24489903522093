import React from 'react'

export default function InternaHeader(props) {
    return (
        <div className="interna-header-container">
            <div className="container">
                <h1>{props.title}</h1>
                <div className="content">
                    <h2><a href='/#agenda'>Ejes</a> / {props.subtitle}</h2>
                    <img src={`/img/${props.icono}`} alt="" />
                </div>
            </div>
        </div>
    )
}