import React from 'react'
import { Link } from 'react-router-dom'
import HeaderComponents from './HeaderComponents'

export default function Agenda(props) {
    return (
        <div id='agenda' className="container agenda-container">
            <HeaderComponents
                title={"<h2><span class='bold'>Agenda</span></h2>"}
                detail={"Te acercamos la propuesta de capacitación a distancia para los próximos meses. Liderazgo, Estrategia y Gestión, Gobierno y Cultura Cooperativa son los ejes temáticos."}
                barra={"agenda-barra.png"}
                icono={"agenda-mano.png"}
                textoVertical={"EJES TEMÁTICOS"}
            />
            <div className="content-agenda">
                <div className="p-column">
                    <div className="item" style={{borderColor:'#9999C8', display:'flex',alignItems:'center'}}>


                        <ul className="text liderazgo">
                            <div style={{color:'#9999C8'}}>
                                <h1><strong>01</strong></h1>
                                <h2><strong>Liderazgo</strong></h2>
                            </div>

                            {/* <Link to='/liderazgo/liderazgo-equipos' >
                                <li>
                                    Liderazgo de equipos
                            </li>
                            </Link>
                            <div className=" fecha fondo-lila texto-blanco liderazgo borde-lila">
                                PRÓXIMAMENTE
                              </div>

                            <Link to='/liderazgo/mondragon' >
                                <li>
                                    Charla “Conocé el Modelo de Liderazgo de MONDRAGON” A cargo de Yolanda Lekuona, del Centro de Otalora desde la Universidad de Mondragón
                            </li>
                            </Link>
                            <div className=" fecha fondo-lila texto-blanco liderazgo borde-lila">
                                PRÓXIMAMENTE
                              </div>
                            */}

                            <Link to='/liderazgo/gestion-personas' >
                                <li>
                                    <strong>Programa "Bienvenidos a la gestión de las Personas" (BGP)</strong>
                                </li>
                            </Link>
                            <div className="liderazgo">
                                <p>
                                    <strong>1era Edición: </strong>
                                    Inicia 6 de marzo - 8:30 a 10:30 hs
                                </p>
                                <p>
                                    <strong>2da Edición: </strong>
                                    Inicia 2 de junio - 8:30 a 10:30 hs
                                </p>
                                <p>
                                    <strong>3era Edición: </strong>
                                    Inicia 2 de septiembre - 8:30 a 10:30 hs
                                </p>
                               
                            </div>
                            <Link to='/liderazgo/gestion-personas-ii' >
                                <li>
                                    <strong>Programa BGP II</strong>
                                </li>
                            </Link>
                            <div className="liderazgo">
                                <p>
                                    <strong>1era Edición: </strong>
                                    Inicia 10 de agosto - 8:30 a 10:30 hs
                                </p>
                                <p>
                                    <strong>2da Edición: </strong>
                                    Inicia 7 de septiembre - 8:30 a 10:30 hs
                                </p>
                                <p>
                                    <strong>3era Edición: </strong>
                                    Inicia 3 de noviembre - 8:30 a 10:30 hs
                                </p>
                               
                            </div>


                            {/* <Link to='/liderazgo/delegacion-control' >
                                <li>
                                    Liderazgo, delegación y control.
                            </li>
                            </Link>

                            <div className=" fecha fondo-lila texto-blanco liderazgo borde-lila">
                                PRÓXIMAMENTE
                              </div>

                            <Link to='/liderazgo/comunicacion-efectiva' >
                                <li>
                                    Comunicación efectiva y uso de las herramientas y prácticas virtuales.
                            </li>
                            </Link>
                            <div className=" fecha fondo-lila texto-blanco liderazgo borde-lila">
                                PRÓXIMAMENTE
                              </div> */}


                        </ul>
                    </div>
                    <div className="item" style={{borderColor:'#F4D03F',display:'flex',justifyContent:'center',alignItems:'center'}}>


                        <ul className="text estrategia">
                            

                            <div style={{color:'#F4D03F'}}>
                                    <h1><strong>02</strong></h1>
                                    <h2 style={{width:'50%'}}><strong>Estrategia y Gestión</strong></h2>
                            </div>

                            <Link to='/estrategia/gestion-integral-cooperativas' >
                                <li>
                                    Programa “Hacia una Gestión Integral de las cooperativas”

                                </li>
                            </Link>
                            <div className="estrategia" style={{lineHeight:1}}>
                                4 y 12 de mayo - 8:30 a 10:30 hs.
                            </div>

                            <Link to='/estrategia/herramientas-abordajes' >
                                <li>
                                    Taller web: Hacia una gestión de las personas 1
                                </li>
                            </Link>
                            <div className="estrategia">
                                7 de mayo - 8:30 a 10:30 hs.
                            </div> 
                            <Link to='/estrategia/herramientas-abordajes-2' >
                                <li>
                                Taller web: Hacia una gestión de las personas 2
                                </li>
                            </Link>
                            <div className="estrategia">
                                10 de Junio - 8:30 a 10:30 hs.
                            </div>

                            <Link to='/'>
                                <li>
                                    Sustentabilidad Coop (proximamente)
                                </li>
                            </Link>
                            <Link to='/'>
                                <li>
                                    RSC del diagnóstico a la acción (proximamente)
                                </li>
                            </Link>

                            {/* <Link to='/estrategia/gestion-cambio' >
                                <li>
                                    Gestión del cambio
                                </li>
                            </Link>
                            <div className="fecha fondo-amarillo texto-blanco estrategia borde-amarillo">
                                PRÓXIMAMENTE
                              </div>


                            */}

                            {/* <Link to='/estrategia/gestion-triple-impacto' >
                                <li>
                                    Desarrollo sostenible. Hacia una gestión con triple impacto
                                </li>
                            </Link>
                            <div className="fecha fondo-amarillo texto-blanco estrategia borde-amarillo">
                                PRÓXIMAMENTE
                              </div>

                            <Link to='/estrategia/descripcion-puestos' >
                                <li>
                                    Descripción de puestos
                                </li>
                            </Link>
                            <div className="fecha fondo-amarillo texto-blanco estrategia borde-amarillo">
                                PRÓXIMAMENTE
                              </div>

                            <Link to='/estrategia/sostener-negocio' >
                                <li>
                                    Programa “Cómo sostener el negocio hoy y mañana. Estrategia y gestión ágil”
                                </li>
                            </Link>
                            <div className="fecha fondo-amarillo texto-blanco estrategia borde-amarillo">
                                PRÓXIMAMENTE
                              </div> */}

                        </ul>
                    </div>
                </div>
                <div className="s-column">
                    <div className="item" style={{borderColor:'#D40030'}}>


                        <ul className="text gobierno">
                            <div style={{color:'#D40030'}}>
                                        <h1><strong>03</strong></h1>
                                        <h2 style={{width:'50%'}}><strong>Gobierno Cooperativo</strong></h2>
                            </div>
                            <Link to="/gobierno-cooperativo/programa-vilca" >
                                <li>
                                    <strong>Programa VILCA</strong>

                                </li>
                            </Link>
                            <div className="gobierno">
                                <p>
                                    <strong>2da Edición: </strong>
                                    Inicia 12 de agosto - 8:30 a 10:30 hs
                                </p>
                            </div>
                            <Link to="/gobierno-cooperativo/programa-vilca-ii">
                                <li>
                                    <strong>Programa VILCA II</strong>
                                </li>
                            </Link>
                            <div className="gobierno">
                                <p>
                                    <strong>1era Edición: </strong>
                                    Inicia 29 de junio - 8:30 a 10:30 hs
                                </p>
                                <p>
                                    <strong>2da Edición: </strong>
                                    Inicia 28 de septiembre - 8:30 a 10:30 hs
                                </p>
                            </div>
                            {/* <Link to="/gobierno-cooperativo/simulador-abierto">
                                <li>
                                    <strong>Simulador Abierto</strong>
                                </li>
                            </Link>
                            <div>
                                <p>Inicia el 10 de mayo - 8:30 a 10:30 hs</p>
                            </div> */}


                            {/* <Link to='/gobierno-cooperativo/modo-remoto' >
                                <li>
                                    Comunicación en modo remoto. Dinámicas y herramientas.
                                </li>
                            </Link>
                            <div className="fecha texto-blanco fondo-rojo gobierno borde-rojo">
                                PRÓXIMAMENTE
                            </div> */}


                        </ul>
                    </div>
                    <div className="item" style={{borderColor:'#43A363'}}>


                        <ul className="text gestion">
                            <div style={{color:'#43A363'}}>
                                <h1><strong>04</strong></h1>
                                <h2 style={{width:'50%'}}><strong>Cultura Cooperativa</strong></h2>
                            </div>
                            <Link to='/gestion/trabajo-equipo' >

                                <li>
                                    <strong>Taller web "Trabajo en equipo. Poniendo en juego los valores cooperativos"</strong>
                              </li>
                            </Link>
                            <div className="gobierno">
                                26 abril - 8:30 a 10:30hs
                            </div>

                            {/* <Link to='/gestion/emociones' >

                                <li>
                                    Cómo gestionar las emociones.
                                </li>
                            </Link>
                            <div className="fecha texto-blanco fondo-verde gobierno borde-verde">
                                PRÓXIMAMENTE
                            </div> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}