import React, { useEffect } from 'react'
import ReactGa from 'react-ga'
import Header from '../Components/Header'
import Contactanos from '../Components/Contactanos'
import Footer from '../Components/Footer'
import InternaTitles from '../Components/InternaTitles'
import ParaQuienes from '../Components/ParaQuienes'
import Separador from '../Components/Separador'
import ScrollAnimation from 'react-animate-on-scroll'


export default function Liderazgo(props) {

    const match = props.match.params.slug

    const lila = '#9999C8'

    useEffect(() => {
        ReactGa.pageview(`/liderazgo/${match}`)
    })


    return (
        <div className="lidereazgo-container">
            <Header
                fondo={'fondo-lila'}
                rayas={'rayas-azules.png'}
                subtitle={'Liderazgo'}
                onlyFirst={true}
                ejeHeader={true}
            />


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                {match == 'gestion-personas' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Programa “Bienvenido a la gestión de las Personas” (BGP). '}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                        mainTitle={'Programa BGP I'}
                                        style={{lineHeight:`${window.screen.width < 768 ? '2rem' : '0rem'}`,marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h4 className="title">Dirigido a líderes de equipos o procesos</h4>
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Reflexionar e intercambiar sobre la experiencia de su rol como líderes cooperativos en el contexto actual. 
                                    Tomar conciencia del impacto de su rol en el desarrollo de las personas, de los equipos, y en la generación de valor para las cooperativas. 
                                    Transformar prácticas, a través de nuevas herramientas, con propósito, y potenciar el impacto de su rol.</p>
                                <h3 className="title">QUÉ</h3>
                                <h4 className="texto-lila">
                                    Módulo 1
                                </h4>
                                <p>
                                    Identificar los principales desafíos de su rol como líderes cooperativos en un contexto de complejidad e incertidumbre. Tomar conciencia de su manera de afrontar la realidad, cuáles son sus formas más fuertes y cuáles a desarrollar para lograr un mejor impacto, en las personas que lideran.
                                </p>

                                <h4 className="texto-lila">
                                    Módulo 2
                                </h4>
                                <p>
                                    Tomar conciencia de la importancia de la calidad de la comunicación, en la construcción del vínculo y en el logro de resultados. Generar prácticas  de conversaciones constructivas poniendo en juego los valores cooperativos para fortalecer vínculos, promover entornos cooperativos y generar valor.
                                </p>
                                <h4 className="texto-lila">
                                    Módulo 3
                                </h4>
                                <p>
                                    La importancia del “propósito”  y de la delegación  como acciones que dan sentido al desarrollo de las personas y del equipo. Identificar cómo el nivel de motivación impacta en el compromiso. Valorar las acciones que como líder contribuyen a esa motivación.
                                 </p>
                                <h4 className="texto-lila">
                                    Módulo 4
                                </h4>
                                <p>
                                    Generar acciones concretas para implementar en sus equipos. Potenciar la cooperación y el aprendizaje entre pares.
                                </p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>4 módulos de 2 horas cada uno, incluye acompañamiento entre módulos con feedback y actividades</p>
                                <div className="container" style={{height:`${window.screen.width < 768 ? "100%": '15rem'}`,marginLeft:'-1rem'}}>
                                    <h3 style={{fontSize:'1rem'}}><strong>FECHA</strong> - 8:30 a 10:30 hs - Seleccione la edición en la que quiera anotarse</h3>
                                    <div className="row">
                                        <div className="col">
                                            <h4>1era Edición</h4>
                                            <p>M1: 6 abril</p>
                                            <p>M2: 13 abril</p>
                                            <p>M3: 20 abril</p>
                                            <p>M4: 30 abril</p>
                                        </div>
                                        <div className="col">
                                            
                                            <h4>2da Edición</h4>
                                            <p>M1: 2 junio</p>
                                            <p>M2: 11 junio</p>
                                            <p>M3: 24 junio</p>
                                            <p>M4: 1 julio</p>
                                            <div className="buttonCustom">
                                                <a target="_blank" href="https://forms.gle/2fJCvBQ2fk4j4cHJA">
                                                    <button className="texto-lila borde-lila boton-lila">¡Inscribirme!</button>
                                                </a>
                                            </div> 
                                        </div>
                                        <div className="col">
                                            <h4>3era Edición</h4>
                                            <p>M1: 2 septiembre</p>
                                            <p>M2: 9 septiembre</p>
                                            <p>M3: 17 septiembre</p>
                                            <p>M4: 29 septiembre</p>
                                            <div className="buttonCustom">
                                                <a target="_blank" href="https://forms.gle/2P6G5AppneBGVtnu8">
                                                    <button className="texto-lila borde-lila boton-lila">¡Inscribirme!</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                       {/*  <div className="col">
                                            <h4>Grupo 4</h4>
                                            <p>M1: 2 septiembre</p>
                                            <p>M2: 9 septiembre</p>
                                            <p>M3: 17 septiembre</p>
                                            <p>M4: 29 septiembre</p>
                                        </div> */}
                                    </div>
                                </div>
                                    {/* <ParaQuienes
                                        onlyButton={true}
                                        colorButton={'fondo-blanco'}
                                        buttonBorderColor={lila}
                                        link={'https://forms.gle/2fJCvBQ2fk4j4cHJA'}
                                        colorLink={lila}
                                        eventName={'Programa “Bienvenido a la gestión de las Personas” (BGP). '}
                                        style={{display:'flex',justifyContent:'flex-end',alignItems:'center', marginTop:'4rem', marginBottom:'-5rem'}}
                                        idName={"liderazgo"}
                                    /> */}
                            </div>
                        </div>
                    </div>

                )}
                {match == 'gestion-personas-ii' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Programa “Bienvenido a la gestión de las Personas” 2 (BGP II). '}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                        mainTitle={'Programa BGP II'}
                                        style={{lineHeight:`${window.screen.width < 768 ? '2rem' : '0rem'}`,marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h4 className="title">Dirigido a líderes de equipos o procesos</h4>
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Promover el intercambio y la conciencia del impacto que como líderes tienen en la conformación de equipos cooperativos y contextos.</p>
                                <h3 className="title">QUÉ</h3>
                                <h4 className="texto-lila">
                                    Módulo 1
                                </h4>
                                <p>
                                    Generar un espacio para que los líderes pongan foco en sus equipos, identificando áreas fuertes y áreas de desarrollo. Diseñar acciones para seguir construyendo y potenciando el propio equipo. El líder cooperativo: nuevas  formas de adaptarse a un mundo de cambio. Esenciales de equipos: los valores (cooperativos) la confianza, la resolución de conflictos, el compromiso grupal, la orientación y el logro de resultados grupales.
                                </p>

                                <h4 className="texto-lila">
                                    Módulo 2
                                </h4>
                                <p>
                                    Generar un espacio para que los líderes pongan foco en sus equipos, identificando áreas fuertes y áreas de desarrollo. Diseñar acciones para seguir construyendo y potenciando el propio equipo. El líder cooperativo: nuevas  formas de adaptarse a un mundo de cambio. Esenciales de equipos: los valores (cooperativos) la confianza, la resolución de conflictos, el compromiso grupal, la orientación y el logro de resultados grupales.
                                </p>
                                <h4 className="texto-lila">
                                    Módulo 3
                                </h4>
                                <p>
                                    Se formará grupos de 4/6 líderes. Trabajarán por 1:20 hora en esos grupos. 3 momentos.
                                    En 3 minutos presenta su problema, el resto escucha.
                                    Terminada la presentación el resto del grupo le formula durante  5/10 minutos preguntas sobre ese problema para ampliar la comprensión ( el arte de preguntar)
                                    El grupo le da recomendaciones durante 5 minutos sobre cómo abordar el problema. El que presentó escucha y toma nota.
                                </p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>3 módulos de 2 horas cada uno, incluye acompañamiento entre módulos con feedback y actividades</p>
                                <div className="container" style={{height:`${window.screen.width < 768 ? "100%": '15rem'}`,marginLeft:'-1rem'}}>
                                    <h3 style={{fontSize:'1rem'}}><strong>FECHA</strong> - 8:30 a 10:30 hs - Seleccione la edición en la que quiera anotarse</h3>
                                    <div className="row">
                                        <div className="col">
                                            <h4>1era Edición</h4>
                                            <p>M1: 10 agosto</p>
                                            <p>M2: 19 agosto</p>
                                            <p>M3: 31 agosto</p>
                                            <div className="buttonCustom">
                                                <a target="_blank" href="https://forms.gle/bKGrDmVg7WkHTPSq9">
                                                    <button className="texto-lila borde-lila boton-lila">¡Inscribirme!</button>
                                                </a>
                                            </div>
                                            
                                        </div>
                                        <div className="col">
                                            <h4>2da Edición</h4>
                                            <p>M1: 7 septiembre</p>
                                            <p>M2: 14 septiembre</p>
                                            <p>M3: 21 septiembre</p>
                                            <div className="buttonCustom">
                                                <a target="_blank" href="https://forms.gle/PVFopJWbP2EwcJnk7">
                                                    <button className="texto-lila borde-lila boton-lila">¡Inscribirme!</button>
                                                </a>
                                            </div>
                                            
                                        </div>
                                        <div className="col">
                                            <h4>3era Edición</h4>
                                            <p>M1: 3 noviembre</p>
                                            <p>M2: 11 noviembre</p>
                                            <p>M3: 24 noviembre</p>
                                            <div className="buttonCustom">
                                                <a target="_blank" href="https://forms.gle/KbtEzZT42qNRFpmw9">
                                                    <button className="texto-lila borde-lila boton-lila">¡Inscribirme!</button>
                                                </a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                       {/*  <div className="col">
                                            <h4>Grupo 4</h4>
                                            <p>M1: 2 septiembre</p>
                                            <p>M2: 9 septiembre</p>
                                            <p>M3: 17 septiembre</p>
                                            <p>M4: 29 septiembre</p>
                                        </div> */}
                                    </div>
                                </div>
                                   {/*  <ParaQuienes
                                        onlyButton={true}
                                        colorButton={'fondo-blanco'}
                                        buttonBorderColor={lila}
                                        link={'https://forms.gle/bKGrDmVg7WkHTPSq9'}
                                        colorLink={lila}
                                        eventName={'Programa “Bienvenido a la gestión de las Personas” 2 (BGP II). '}
                                        style={{display:'flex',justifyContent:'flex-end',alignItems:'center', marginTop:`${window.screen.width < 768 ? "4rem": '0rem'}`, marginBottom:'-5rem'}}
                                        idName={"liderazgo"}
                                    /> */}
                            </div>
                        </div>
                    </div>

                )}

                {match == 'mondragon' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Charla “Conocé el Modelo de Liderazgo de MONDRAGON” A cargo de Yolanda Lekuona, del Centro de Otalora desde la Universidad de Mondragón'}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div style={{ marginBottom: '2rem' }} className="interna-text">
                                <h3 className="title">1) Etapas previas:</h3>
                                <p>Modelo por modelaje. <br></br>
                                Primer modelo explícito. Cómo se definió. <br></br>
Cómo se fue desarrollando.<br></br>
                                </p>
                                <h3 className="title">2) Vinculado al modelo de desarrollo de la cultura constructiva-cooperativa</h3>
                                <p></p>

                                <h3 className="title">3)Nuevo modelo de liderazgo: </h3>

                                <p>Nuevas competencias de futuro. <br></br>
Mirada integral: comportamientos por roles. <br></br>
Nueva forma de aprendizaje y desarrollo. <br></br>
Dificultades del cambio de paradigma y cómo superarlas.</p>

                                <h3 className="title">4) Modelo inspirador: cada Cooperativa puede crear el suyo propio. </h3>

                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a todos los integrantes de las cooperativas'}
                            duracionContent={'9 a 10.30'}
                            cuandoContent={''}
                            borderColor={'borde-lila'}
                            colorButton={'fondo-lila'}
                            colorLink={'texto-lila'}
                            eventName={'Charla “Conocé el Modelo de Liderazgo de MONDRAGON” A cargo de Yolanda Lekuona, del Centro de Otalora desde la Universidad de Mondragón'}
                        // link={'https://forms.gle/SzZm9hwfGK6RqV6L6'}
                        />
                    </div>
                )}

                {match == 'liderazgo-equipos' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Liderazgo de equipos'}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Generar un espacio para que los líderes pongan foco en sus equipos identificando las fortalezas  y las  áreas de desarrollo. Diseñar acciones para seguir construyendo y potenciando el propio equipo.</p>
                                <h3 className="title">CONTENIDOS</h3>



                                <p>¿Cómo construir y potenciar los equipos de trabajo desde el rol de líderes?
                                En un mundo complejo, lo individual, los silos, no sirven. Construir equipo, pasar de lo competitivo a lo colaborativo es hoy la forma de generar valor, de alcanzar mejores resultados. <br></br>

                                •        De grupo de personas a equipo. Herramienta de diagnóstico.<br></br>
                                •        Capacidades a desarrollar para construir equipo: la confianza, la resolución de conflictos, el compromiso grupal, la orientación y el logro de resultados grupales. <br></br>
                                •        Herramientas para construir y potenciar el equipo.

</p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a quienes realizaron el Programa BGP.'}
                            duracionContent={'8:30 a 10:30 Hs'}
                            cuandoContent={''}
                            borderColor={'borde-lila'}
                            colorButton={'fondo-lila'}
                            colorLink={'texto-lila'}
                            eventName={'Liderazgo de equipos'}
                        // link={'https://forms.gle/561cUTURfNjBTyRk6'}
                        />
                    </div>
                )}


                {match == 'delegacion-control' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Liderazgo, delegación y control'}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Tomar conciencia de lo que implica delegar, del impacto que tiene en la motivación de las personas del equipo, en la productividad y en la generación de valor.</p>
                                <h3 className="title">CONTENIDOS</h3>



                                <p>El arte de delegar, conversaciones sobre autonomía y responsabilidad. Estamos en un momento crítico y único donde se hace visible formas de liderar que ya no parecieran servir más. Compartir un espacio físico con las personas del equipo, muchas veces favorece la falta de autonomía. El trabajo remoto rompe la “ilusión del control”.
Qué es delegar. Cuáles son los componentes de la delegación. Cuáles son las barreras personales que dificultan la delegación. La mirada hacia cada una de las personas del equipo. Conversaciones y acuerdos. Buenas prácticas.</p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Líderes de proyectos y/o equipos.'}
                            duracionContent={'1 hora y media.'}
                            cuandoContent={''}
                            borderColor={'borde-lila'}
                            colorButton={'fondo-lila'}
                            colorLink={'texto-lila'}
                            eventName={'Liderazgo, delegación y control'}
                        />
                    </div>
                )}

                {match == 'comunicacion-efectiva' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-lila.png'}
                                        subtitle={'Comunicación efectiva y uso de las herramientas y prácticas virtuales'}
                                        titleColor={'texto-lila'}
                                        subtitleColor={'texto-lila'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Conocer y poner en práctica herramientas y prácticas de comunicación remota para hacer más efectivas las interacciones. Tener mayor confianza en este nuevo terreno virtual.</p>
                                <h3 className="title">CONTENIDOS</h3>



                                <p>Se trabajará alrededor de 5 ejes que resultarán clave para mantener conversaciones efectivas de manera remota.
                                FOCO y claridad en los mensajes. ¿Cómo planificar los mensajes con un foco claro y simple? ¿Cómo adaptar los mensajes de acuerdo a las características de mis interlocutores?
                                Conversar sobre los OBJETIVOS y SU AVANCE. Al trabajar de manera remota y perder visibilidad se corre el riesgo de caer en el sobrecontrol  o el abandono de los colaboradores. Es clave definir y acordar objetivos de corto plazo y poder conversar sobre estos de manera periódica.
                                Abrir espacios para PREGUNTAS y cuestionamientos. El trabajo en aislamiento puede provocar que algunas personas sientan temor de plantear dudas, o cuestionar algunas ideas. Entrenaremos cómo generar espacios para habilitar preguntas y desafíos con los colaboradores y colegas.
                                Facilitar REUNIONES de equipo. Cómo preparar y facilitar reuniones de equipo a través las plataformas virtuales para diversos objetivos.
                                EMPATÍA y conversar sobre las EMOCIONES. Cómo mantenernos perceptivos con nuestros interlocutores a través de los medios virtuales.¿Qué hacer y cómo favorecer que los otros se compartan sus emociones?

</p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'A todos los interesados.'}
                            duracionContent={'1 hora y media.'}
                            cuandoContent={''}
                            borderColor={'borde-lila'}
                            colorButton={'fondo-lila'}
                            colorLink={'texto-lila'}
                            eventName={'Comunicación efectiva y uso de las herramientas y prácticas virtuales'}
                        />
                    </div>
                )}
            </ScrollAnimation>


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >

                <Separador
                    image={'../img/fondo-separador-2.svg'}
                    parallax={'parallax-img'}
                />
            </ScrollAnimation>

            <Contactanos />
            <Footer />
        </div>
    )
}