import React, { useState } from 'react'
import HeaderComponents from './HeaderComponents'
import Modal from "react-bootstrap/Modal";


export default function Material(props) {

    const [showBanner, setShowBanner] = useState(false);

    const handleTrueBanner = () => {
        setShowBanner(true);
    }
    const handleFalseBanner = () => setShowBanner(false);

    return (
        <div id='caja-de-herramientas' className="material-container">
            <div className="fondo-rayas-2"></div>
            <div className="container">
                <HeaderComponents
                    title={"<h2 class='width60' ><span class='bold'>Material</span> a tu alcance</h2>"}
                    detail={'Accedé a una serie de tips que te ayudarán a vos y a tu cooperativa en estos momentos de cambio. '}
                    barra={'agenda-barra.png'}
                    icono={'diagrama.png'}
                    textoVertical={"CAJA DE HERRAMIENTAS"}
                />
                <div className="material-content">
                    <img onClick={() => {
                        console.log("OTRO CLICK")
                        handleTrueBanner()
                    }} className="tips" src="/img/tips.png" alt="" />
                    <div className="video-container">
                        <img src="/img/material-video.png" alt="" />
                        {/* <iframe width={window.screen.width > 768 ?
                            '400px' :
                            '300px'
                        } height={window.screen.width > 768 ?
                            '230' :
                            '180'
                        } src='/img/video-material.mp4' ></iframe> */}
                        <video width={window.screen.width > 768 ?
                            '400px' :
                            '300px'
                        } height={window.screen.width > 768 ?
                            '230' :
                            '180'
                        }
                            controls
                        >
                            <source src="/img/video-material.mp4"></source>
                        </video>
                    </div>

                </div>
            </div>
            <div className="fondo-rayas"></div>

            <Modal
                centered
                show={showBanner}
                onHide={handleFalseBanner}
                className="banner-home-modal"
                size="xl"
                className={window.screen.width > 768 ?
                    "banner-herramientas" :
                    "banner-herramientas-mobile"
                }
            >
                {window.screen.width > 768 ?
                    <img src="/img/infografia-escritorio.png" alt="" /> :
                    <img src="/img/infografia-mobile.png" alt="" />
                }
            </Modal>
        </div>
    )
}