import React from 'react'

export default function InternaTitles(props) {
    return (
        <div className="header-components-container" style={props.style}>
            <div className="vertical">
                <h6 className='texto-vertical' >{props.textoVertical}</h6>
                <img src={`/img/${props.barra}`} alt="" />
            </div>
            <div className="interna-title">
                <h1 className={`${props.titleColor}`}><strong style={{fontSize:'inherit'}}>{props.mainTitle}</strong></h1>
                <h2 className={`${props.titleColor}`} >{props.title}</h2>
                <p className={`subtitle-interna ${props.subtitleColor}`} >{props.subtitle}</p>
            </div>
        </div>
    )
}