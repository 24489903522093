import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import Slider from './SliderHome'
import InternaHeader from './InternaHeader'
import NavbarHeader from './NavbarHeader'
import Modal from "react-bootstrap/Modal";


export default function Header(props) {

    const [buttonClass, setButtonClass] = useState('disabled')

    const [showBanner, setShowBanner] = useState(false);

    const handleTrueBanner = () => {
        setShowBanner(true);
    }
    const handleFalseBanner = () => setShowBanner(false);


    const handleButtonClass = () => {
        if (buttonClass == 'disabled') {
            setButtonClass('open')
        } else {
            setButtonClass('disabled')
        }
    }

    return (
        <div className={`header-container ${props.onlyFirst ? (props.ejeHeader ? props.fondo : "fondo-azul-oscuro") : props.fondo}`} style={{height:`${ props.onlyFirst ? (props.ejeHeader ? '30vh' : '70vh') : '100vh'}`}}>
            <a href='/' >
                <img className={"logo-nodos"} src="/img/logo-nodos.svg" alt="logo-nodos"/>
            </a>
            <NavbarHeader
                buttonClass={buttonClass}
            />
            <div onClick={() => handleButtonClass()} className="menu">
                <Menu
                    buttonClass={buttonClass}
                />
            </div>
            {props.slider ? (
                <Slider onlyFirst={props.onlyFirst} />
            ) : (
                    <InternaHeader
                        title={props.title}
                        subtitle={props.subtitle}
                        icono={props.icono}
                        ejeHeader={props.ejeHeader}
                        fondo={props.fondo}
                    />
                )}
            {/* <img className="logo-luz" src="/img/icono-luz-amarillo.png" alt="luz-amarilla" /> */}
            <div onClick={() => {
                handleTrueBanner()
            }} className="logo-luz"></div>
            <img className="logo-rayas" src={`/img/${props.rayas}`} alt="rayas-amarilla" style={{top:`${props.ejeHeader ? '25%' : ""}`}}/>
            <Modal
                centered
                show={showBanner}
                onHide={handleFalseBanner}
                className="duda-modal"
                size="l"
            >
                <Modal.Body>
                    <div className="modal-duda-container">
                        <div onClick={() => {
                            handleFalseBanner()
                        }} className="p cerrar-boton">Cerrar</div>
                        <div className="fondo-title fondo-amarillo"></div>
                        <div className="header">
                            <h1>¿Tenés alguna duda?</h1>
                            <img src="/img/icono-lamparita.png" alt="" />
                        </div>
                        <p className="text" >Si tenés alguna consulta o querés una capacitación a medida para tu cooperativa</p>
                        <a
                            href="/#contactanos"
                            aria-current="page"
                            onClick={() => {
                                handleFalseBanner()
                            }}
                        >
                            <p className="link" >¡Escribinos!</p>
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
} 