import React from 'react'
import HeaderComponents from './HeaderComponents'

export default function Reflexionar(props) {
    return (
        <div id='colaboratorios' className="reflexionar-container">
            <div className="container">
                <HeaderComponents
                    title={"<h2>Un espacio para <span class='bold'>reflexionar</span> el cambio</h2>"}
                    detail={'Conocé la experiencia de otras cooperativas sobre cómo hacen las cosas. Compartir el conocimiento es una forma de potenciar el cooperativismo. '}
                    icono={'people-icono.png'}
                    barra={'barra-verde.png'}
                    textoVertical={"COLABORATORIO"}
                    width={'50'}
                    marginRight={'6'}
                />
                <div className="videos-container">
                    <div className="video">
                        <img className="video-image" src="/img/video-1-lineas.svg" alt="" />
                        <iframe className="video-image" src="https://www.youtube.com/embed/Owh0Vb2ppRE" frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            width='100%'
                            height='260px'
                            title='video'></iframe>
                        <img src="/img/video-2-abajo.svg" alt="" />
                        <h4>La experiencia de Cooperativa Ascensión</h4>
                        <p>Diego Scarafía, gerente de Cooperativa Ascensión, relata de qué manera se adaptaron rápidamente a la nueva dinámica de trabajo. Destaca la incorporación de las herramientas tecnológicas para continuar con las actividades de la cooperativa y la cooperación de los productores en un momento donde la virtualidad reemplazó el contacto cara a cara.</p>

                    </div>
                    <div className="video segundo-video">
                        <iframe src="https://www.youtube.com/embed/ziuf94gaNaQ" frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            width='100%'
                            height='260px'
                            title='video' ></iframe>
                        <img className="video-image" src="/img/video-1-abajo.svg" alt="" />
                        <h4>Las vivencias de la Cooperativa Agraria Tres Arroyos </h4>
                        <p>Los integrantes de la cooperativa destacan la colaboración y la capacidad de adaptación de todo el personal de la cooperativa frente a las nuevas prácticas, sobre todo en lo que respecta a la incorporación de canales de comunicación virtuales y nuevos horarios.  </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

