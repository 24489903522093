import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export default function HeaderComponents(props) {
    return (
        <div className="header-components-container">
            <div className="vertical">
                <h6 className='texto-vertical' >{props.textoVertical}</h6>
                <img src={`/img/${props.barra}`} alt="" />
            </div>
            <div style={{ width: `${props.width}%`, marginRight: `${props.marginRight}rem` }} className="title">
                {ReactHtmlParser(props.title)}
                <p>{props.detail}</p>
            </div>
            <div className="agenda-imagen">
                <img src={`/img/${props.icono}`} alt="" />
            </div>
        </div>
    )
}


