import React from 'react'

export default function Footer(props) {
    return (
        <div>
            <div className="footer-container">
                <div className="container">
                    <div className="content">
                        <div className="text">
                            <p className="no-bottom" >Av. Madero 942 4ºpiso (1414) - CABA, Argentina</p>
                            <a href="mailto: capacitacion@nodosfundacion.com.ar">capacitacion@nodosfundacion.com.ar</a>
                        </div>
                        <img src="/img/logo-nodos.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="footer-container-logos">
                <div className="container" style={{display:'flex',justifyContent:'space-evenly'}}>
                    <img src="/img/ACA_logo_gris.svg" alt="Logo ACA (Asociación de Cooperativas Argentinas)" />
                    <img src="/img/footer-nodos-logo2.svg" alt="Logo La Segunda" />
                    <img src="/img/footer-nodos-logo3.svg" alt="Logo Avalian" />
                    <img src="/img/footer-nodos-logo4.svg" alt="Logo Coovaeco" />
                </div>
            </div>
        </div>
    )
}