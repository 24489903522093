import React from 'react'
import ReactGa from 'react-ga'

export default function ParaQuienes(props) {

    const clickInscribirme = () => {
        ReactGa.event({
            category: 'Button',
            action: `Inscribirse: ${props.eventName}`
        })
    }

    const clickInfo = () => {
        ReactGa.event({
            category: 'Button',
            action: `Pedí más info: ${props.eventName}`
        })
    }

    return (
        <div className="container" style={props.style}>
            <div className={`para-quienes-container ${props.borderColor ? props.borderColor : ""}`}>
                {
                    props.onlyButton ?
                        <a href={props.link} target="_blank">
                            <button onClick={clickInscribirme} className={`button ${props.colorButton}`} id={`${props.idName}`} style={{borderColor:`${props.buttonBorderColor}`,color:`${props.colorLink}`}}>¡INSCRIBIRME!</button>
                        </a>
                    :
                        <>
                        <div className="header">
                            <h3 className="title">PARA QUIÉNES</h3>
                            <a href="/#agenda" className={props.colorLink} >VER MAS CAPACITACIONES</a>
                        </div>
                        <p>{props.paraQuienesText}</p>
                        <h3 className="title">DURACIÓN</h3>
                        <p>{props.duracionContent}</p>
                        <h3 className="title">CUÁNDO</h3>
                        {props.cuandoContent ? (
                            <p>{props.cuandoContent}</p>
                        ) : (
                                <p>Aún no hay fechas programadas</p>
                            )}
                        {props.link ? (
                            <a href={props.link} target="_blank">
                                <button onClick={clickInscribirme} className={`button ${props.colorButton}`}>¡INSCRIBIRME!</button>
                            </a>
                        ) : (
                                <a href="/#contactanos">
                                    <button onClick={clickInfo} className={`button ${props.colorButton}`}>¡PEDÍ MÁS INFO!</button>
                                </a>
                            )}
                        </>
                } 
            </div>
        </div>
    )
} 