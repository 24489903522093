import React from 'react'

export default function Menu(props) {
    return (
        <div className="menu-container">
            <div className="button">
                <img className="swiper-button" src="/img/swiper-button-menu.png" alt="" />
                <div className={`button-menu ${props.buttonClass}`}>
                    <div id="nav-icon4">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {/* {props.buttonClass == 'disabled' ? (
                    <div>
                    </div>
                ) : (
                        <div>
                            <img src="/img/cerrar.svg" alt="" />
                        </div>
                    )} */}
            </div>
        </div>
    )
}