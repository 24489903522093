import React, { useEffect } from 'react'
import Header from '../Components/Header'
import ReactGa from 'react-ga'
import Contactanos from '../Components/Contactanos'
import InternaTitles from '../Components/InternaTitles'
import Footer from '../Components/Footer'
import ParaQuienes from '../Components/ParaQuienes'
import Separador from '../Components/Separador'
import ScrollAnimation from 'react-animate-on-scroll'



export default function Estrategia(props) {

    const match = props.match.params.slug
    const amarillo = '#FFCE00'

    useEffect(() => {
        ReactGa.pageview(`/estrategia/${match}`)
    })

    return (
        <div className="estrategia-container">
            <Header
                fondo={'fondo-amarillo'}
                rayas={'rayas-azules.png'}
                subtitle={'Estrategia y Gestión'}
                onlyFirst={true}
                ejeHeader={true}

            />

            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                {match == 'sostener-negocio' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Programa “Cómo sostener el negocio hoy y mañana. Estrategia y Gestión ágil” '}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Acompañar al equipo gerencial de las cooperativas a través de compartir reflexiones y herramientas que promuevan nuevos aprendizajes con los que dar respuesta a este contexto complejo e incierto.</p>
                                <h3 className="title">CONTENIDOS</h3>


                                <h4 className="texto-amarillo">
                                    Módulo 1
                </h4>
                                <p>Aprender a leer el contexto más rápido, poner foco y priorizar, actuar con rapidez. </p>

                                <h4 className="texto-amarillo">Módulo 2</h4>
                                <p>Conocer herramientas para pensar el día de mañana.</p>
                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Equipos gerenciales de las cooperativas'}
                            duracionContent={'Este programa integra 2 encuentros de 1 hora y media'}
                            cuandoContent={''}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            colorLink={'texto-amarillo'}
                            eventName={'Programa “Cómo sostener el negocio hoy y mañana. Estrategia y Gestión ágil” '}
                        />
                    </div>
                )}
                {match == 'comunidad-medioambiente' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Hacia una gestión integral de la cooperativa'}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Explorar los beneficios y modelos para una gestión preventiva, integral y sustentable de las cooperativas (en oposición a una gestión reactiva, operacional y no sostenible)..</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>Módulo 1: Concepto y Beneficios de una Gestión Integral – modelos y beneficios.</p>
                                <p>Módulo 2: Modelos de Gestión Integral – estrategias y desafíos.</p>


                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Gerentes y funcionarios de las cooperativas. '}
                            duracionContent={''}
                            cuandoContent={''}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            link={''}
                            colorLink={'texto-amarillo'}
                            eventName={'Hacia una gestión integral de la cooperativa'}
                        />
                    </div>
                )}

                {match == 'gestion-cambio' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Gestión del cambio'}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p> Comprender qué hace que los cambios organizacionales sean exitosos o fracasen.</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>  a) Modelos que explican el fenómeno del cambio organizacional, <br></br>
                                b) Encuesta para conocer cuán preparados estamos para el cambio en nuestra cooperativa, <br></br>
                                 c) Contenidos de un Plan de transformación d) Claves para comenzar.</p>



                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a funcionarios de las cooperativas'}
                            duracionContent={'8.30 a 10 Hs'}
                            cuandoContent={''}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            //link={'https://forms.gle/gaBNxAySvqWaWJLU7'}
                            colorLink={'texto-amarillo'}
                            eventName={'Gestión del cambio'}
                        />
                    </div>
                )}

                {match == 'herramientas-abordajes' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        title={'Taller Web "Hacia una gestión de las personas I"'}
                                        titleColor={'texto-amarillo'}
                                        subtitle={'Dirigido funcionarios de las cooperativas y/o quienes tengan a su cargo la gestión de las personas'}
                                        textoVertical={'EJES'}
                                        style={{lineHeight:'2rem',marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <p>  Conocer las mejores prácticas y herramientas de gestión de personas. Identificar los desafíos y puntos de dolor propios. Co-construir un mapa de las herramientas requeridas para evolucionar.</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p> Mejores prácticas y herramientas de gestión de personas. El líder con triple impacto. Cómo diseñar un equipo de trabajo. Estructura, áreas y principios. Cómo definir los roles. Identificación y definición de los roles y descripción de puestos. Cómo seleccionar a los mejores candidatos. Cómo gestionar el desempeño. Cómo desarrollar nuevas capacidades. Cómo apalancar la motivación para lograr los mejores resultados.</p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>1 módulo de 2 horas</p>
                                <h3 className="title">FECHA</h3>
                                <p>7 de mayo - 8:30 a 10:30 hs</p>
                                <ParaQuienes
                                    onlyButton={true}
                                    buttonBorderColor={amarillo}
                                    colorButton={'fondo-blanco'}
                                    link={'https://forms.gle/DPckVj92SCvojzJY8'}
                                    colorLink={amarillo}
                                    eventName={'Hacia una gestión de las personas: herramientas y abordajes'}
                                    style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                    idName={"estrategia"}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {match == 'herramientas-abordajes-2' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        title={'Taller Web "Hacia una gestión de las personas. Taller de aplicación"'}
                                        titleColor={'texto-amarillo'}
                                        subtitle={'Dirigido funcionarios de las cooperativas y/o quienes tengan a su cargo la gestión de las personas'}
                                        textoVertical={'EJES'}
                                        style={{lineHeight:'2rem',marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Hacer zoom en cada etapa abordada en el taller I, para que cada participante pueda llevarse una "caja de herramientas" en la cual apoyar a su día a día de la gestión de personas</p>
                                <h3 className="title">QUÉ</h3>
                                <p>Diferentes tipos de entrevistas (por competencias, de feedback)</p>
                                <p>Role playing</p>
                                <p>Feedback jefe/colaborador, tipos de feedback</p>
                                <p>Modelo 70/20/10</p>
                                <h3 className="title">DURACIÓN</h3>
                                <p>1 módulo de 2 horas.</p>
                                <h3 className="title">FECHA</h3>
                                <p>10 de Junio - 8:30 a 10:30 hs</p>
                                <ParaQuienes
                                    onlyButton={true}
                                    buttonBorderColor={amarillo}
                                    colorButton={'fondo-blanco'}
                                    link={'https://forms.gle/rEVNGGNPyyuSrH3S9'}
                                    colorLink={amarillo}
                                    eventName={'Hacia una gestión de las personas: herramientas y abordajes'}
                                    style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                    idName={"estrategia"}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {match == 'gestion-triple-impacto' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Desarrollo sostenible. Hacia una gestión con triple impacto'}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p> Incrementar la competitividad y sustentabilidad de las cooperativas, mejorando la calidad
                                de gestión y sus resultados. <br></br>
                                Comenzar a inspirar y explorar el desarrollo de una Cultura Cooperativa Sostenible con foco en las tres dimensiones de impacto: económico, social y ambiental.

</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>Visión integral sostenible: creación de valor económico. <br></br>
Desarrollo de comunidades: creación de valor social. <br></br>
Gestión ambiental: creación de valor ambiental. </p>



                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a funcionarios de las cooperativas'}
                            duracionContent={'8:30 a 10 Hs'}
                            cuandoContent={''}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            //link={'https://forms.gle/mk1mc8t58JTfree7A'}
                            colorLink={'texto-amarillo'}
                            eventName={'Desarrollo sostenible. Hacia una gestión con triple impacto'}
                        />
                    </div>
                )}

                {match == 'gestion-integral-cooperativas' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        title={'Programa “Hacia una Gestión Integral de las cooperativas”'}
                                        titleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                        subtitle={'Dirigido funcionarios de las cooperativas'}
                                        style={{lineHeight:'2rem',marginLeft:`${window.screen.width < 768 ? '0.5rem' : '3.5rem'}`}}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <br/>
                                <h3 className="title">PARA QUÉ</h3>
                                <br/>
                                <p> Fortalecer la comprensión de la gestión integral de cooperativas, brindando modelos y herramientas prácticas para avanzar en la implementación.</p>
                                <h3 className="title">QUÉ</h3>
                                <br/>
                                <p>
                                    <h4 className="texto-amarillo">Módulo 1</h4>
                                    Introducción a la gestión integral - Conceptos y Beneficios
                                </p>
                                <p>Explorar los beneficios de una gestión preventiva, integral y sustentable (en oposición a una gestión reactiva, operacional y no sostenible).
                                    Reconocer las etapas del camino, entender la situación actual, identificar barreras e impulsores
                                </p>
                                <br/>
                                <p>
                                    <h4 className="texto-amarillo">Módulo 2</h4> Modelos y Herramientas para una gestión integral
                                </p>
                                <p>Indagar en elementos y componentes de una gestión integral, compartir modelos. Ejercicio de simulación de mejora continua de la gestión</p>
                                <br/>
                                <h3 className="title">DURACIÓN</h3>
                                <p>2 módulos de 1 hora 30 minutos cada uno</p>
                                <h3 className="title">FECHA</h3>
                                <p>4 y 12 de mayo - 8:30 a 10:30 hs</p>
                                <ParaQuienes
                                    onlyButton={true}
                                    buttonBorderColor={amarillo}
                                    colorButton={'fondo-blanco'}
                                    link={'https://forms.gle/xXNYhZjU7fhbe2BZ9'}
                                    colorLink={amarillo}
                                    eventName={'Programa “Hacia una Gestión Integral de las cooperativas”'}
                                    style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}
                                    idName={"estrategia"}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {match == 'descripcion-puestos' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Descripción de puestos'}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>Identificar y comprender los aspectos organizacionales que son necesarios tener claros y definidos a la hora de elaborar los Descriptivos de Puestos.</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>Co-construir y recorrer el camino, a fin de vivenciar experiencialmente las etapas para diseñar la organización de un área o departamento en forma eficiente, con clara definición de roles.
                                Diseñar un descriptivo de puesto funcional al área al que pertenece.
¿Cómo organizar un área o un departamento? ¿Cómo definir roles?</p>



                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a quienes se ocupan de la gestión de las personas.'}
                            duracionContent={'8.30 a 10.30 HS'}
                            cuandoContent={'14 de Octubre'}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            link={'https://forms.gle/HgmVNSZXd2CN59cM7'}
                            colorLink={'texto-amarillo'}
                            eventName={'Descripción de puestos'}
                        />
                    </div>
                )}

                {match == 'sustentabilidad' && (
                    <div>
                        <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                            <div className="interna-content">
                                <div className="container">
                                    <InternaTitles
                                        barra={'barra-amarilla.png'}
                                        subtitle={'Sustentabilidad'}
                                        titleColor={'texto-amarillo'}
                                        subtitleColor={'texto-amarillo'}
                                        textoVertical={'EJES'}
                                    />
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div className="container">
                            <div className="interna-text">
                                <h3 className="title">PARA QUÉ</h3>
                                <p>  Incrementar la competitividad y sustentabilidad de las cooperativas, mejorando la calidad
                                de gestión y sus resultados.
Comenzar a inspirar y explorar el desarrollo de una Cultura Cooperativa Sostenible con foco en las tres dimensiones de impacto: económico, social y ambiental.</p>
                                <h3 className="title">CONTENIDOS</h3>
                                <p>Visión integral sostenible: creación de valor económico. <br></br>
Desarrollo de comunidades: creación de valor social.<br></br>
Gestión ambiental: creación de valor ambiental. </p>



                            </div>
                        </div>
                        <ParaQuienes
                            paraQuienesText={'Dirigido a funcionarios de las cooperativas'}
                            duracionContent={'8:30 a 10 Hs'}
                            cuandoContent={''}
                            borderColor={'borde-amarillo'}
                            colorButton={'fondo-amarillo'}
                            //link={'https://forms.gle/mk1mc8t58JTfree7A'}
                            colorLink={'texto-amarillo'}
                            eventName={'Sustentabilidad'}
                        />
                    </div>
                )}

            </ScrollAnimation>


            <ScrollAnimation animateOnce='true' delay='300' duration='1' animateIn='fadeIn' >
                <Separador
                    image={'../img/fondo-separador-2.svg'}
                    parallax={'parallax-img'}
                />
            </ScrollAnimation>
            <Contactanos />
            <Footer />
        </div>
    )
}